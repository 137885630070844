
<template>
    <div v-if="$can('estimacion.menu.consultar.cotizacion')">
        <ModuloCotizacion
            :monedasimbolo="config.monedasimbolo"
            :userid="config.userid"
            :almacenid="usuario.almacenID"
            :preciocifradecimal="config.preciocifradecimal" 
			:preciocifraentera="config.preciocifraentera" 
			:precioreglatres="config.precioreglatres"
        />
    </div>
</template>

<script>
    import ModuloCotizacion from './Modulos/ModuloCotizacion.vue'

    export default {
        props: [
            'usuario',
            'config'
        ],

        components: {
            ModuloCotizacion
        }
    }
</script>