<template>
    <div>
        <a-row type="flex" :gutter="[2, 5]">
            <a-divider orientation="left">
				<span style="color:black; font-size:18px;">Cotizaciones</span> <span style="color:#9C9C9C; font-size:12px;">Visualiza tus comprobantes de cotizaciones</span>
			</a-divider>

            <a-col flex="1 1 100px">
                <a-button 
                    block 
                    type="primary" 
                    @click="openMenuQueryCotizacion('nuevacotizacion', 1)" 
                    v-if="$can('estimacion.menu.nueva.cotizacion')"
                    >
                    <a-icon type="plus" /> Nueva Cotizacion
                </a-button>
            </a-col>
            
            <a-col flex="1 1 100px">
                <a-button block type="dashed" @click="openModalConsultaOpciones()"><a-icon type="search" /> Consultar Opciones</a-button>
            </a-col>

            <a-col flex="1 1 200px">
				<a-date-picker 
                    :value="utils.getDateFormat(consulta.fecha_inicio)" 
                    @change="onChangeFechaInicio" 
                    :locale="locale" 
                    placeholder="Fecha Inicio"
                    style="width: 100%"
                />
            </a-col>

            <a-col flex="1 10 200px">
				<a-date-picker 
                    :value="utils.getDateFormat(consulta.fecha_final)" 
                    @change="onChangeFechaFinal" 
                    :locale="locale" 
                    placeholder="Fecha Final"
                    style="width: 100%"
                />
			</a-col>

            <a-col flex="1 1 340px">
                <a-input-search placeholder="Buscar por codigo de cotizacion" @search="onSearchCotizacion">
                    <a-button type="primary" slot="enterButton">
                        Buscar
                    </a-button>
                </a-input-search>
            </a-col>

            <a-col :span="1">
                <a-button @click="obtenerListadoActual">
                    <a-icon type="reload" />
                </a-button>
            </a-col>
        </a-row>

        <br>

        <a-table 
                :columns="columns" 
                :data-source="cotizaciones" 
                :pagination="paginate"
                :loading="loading"
                :ellipsis="true"
                size="small"
                :rowKey="record => record.cotizacion_id"
                :scroll="{ x: 980 }"
                @change="handlePagination"
                >

                <template slot="cliente" slot-scope="item">
                    <span>{{ item.cliente.nombreFactura }}</span>
                </template>

                <template slot="fecha" slot-scope="item">
                    <span>{{ item.fechaCotizacion }} {{ item.horaCotizacion }}</span>
                </template>

                <template slot="estado" slot-scope="item">
                    <a-tag color="green" v-if="item.estado === 'APROBADO'">
                        {{ item.estado }}
                    </a-tag>
                    <a-tag color="red" v-if="item.estado === 'ANULADO'">
                        {{ item.estado }}
                    </a-tag>
                </template>

                <span slot="expandedRowRender" slot-scope="item" style="margin: 0">
                    <span style="font-weight:700; font-size:17px;">DATOS DE REGISTRO</span>
                    <a-row>
                        <a-col :span="12">
                            <a-list size="small" bordered><a-list-item>COTIZACION ID:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>CODIGO:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>SUCURSAL:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>CLIENTE:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>OBSERVACION:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>FORMA DE PAGO:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>COSTO EXTRA:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>TIEMPO DE ENTREGA:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>VALIDEZ:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>MONEDA:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>USUARIO:</a-list-item></a-list>
                        </a-col>
                        <a-col :span="12">
                            <a-list size="small" bordered><a-list-item>{{ item.cotizacion_id }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.codigo }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.almacen.nombre }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.cliente.nombreFactura }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ (item.observacion) ? item.observacion : 'SIN OBSERVACION' }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.formapago }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.servicioExtra }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.tiempoEntrega }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.validez }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.nombreMoneda }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.usuario }}</a-list-item></a-list>
                        </a-col>
                    </a-row>
                </span>

                <template slot="action" slot-scope="item">
                    <a-dropdown>
                        <a-menu slot="overlay">
                            <a-menu-item :key="buttonKeyEditarCotizacion">
                                    <a-tooltip placement="topLeft">
                                        <template slot="title">
                                            <span>{{ item.codigo }}</span>
                                        </template> 
                                        <a href="#" @click.prevent="openModalEditarCotizacion(item)" v-if="item.is_cotizacion_editar === 'SIN EDITAR'">
                                            <a-icon type="edit" :style="{ fontSize: '18px', color: '#006ccc' }"/> &nbsp;<strong>Editar Cotizacion {{ item.codigo }}</strong>
                                        </a>

                                        <a href="#" @click.prevent="openModalEditarCotizacion(item)" v-if="item.is_cotizacion_editar === 'REALIZADO'">
                                            <a-icon type="retweet" :style="{ fontSize: '18px', color: '#08AC00' }"/> &nbsp;<strong>Editar Cotizacion {{ item.codigo }}</strong>
                                        </a>
                                    </a-tooltip>
                                </a-menu-item>

                            <a-menu-item :key="buttonKeyVisualizarComprobante">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>Visualizar {{ item.codigo }}</span>
                                    </template>
                                    <a href="#" @click.prevent="abrirModalSalida(item)"><a-icon type="eye" :style="{ fontSize: '18px', color: '#006ccc' }"/> &nbsp;<strong>Visualizar Comprobante</strong></a>
                                </a-tooltip>
                            </a-menu-item>

                            <a-menu-item :key="buttonKeyVerReporte">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>Ver Comprobante PDF {{ item.codigo }}</span>
                                    </template>
                                    <a href="#" @click.prevent="reporteCarta(item.cotizacion_id)"><a-icon type="file-pdf" :style="{ fontSize: '18px', color: '#fa1d03' }"/> &nbsp;<strong>Comprobante PDF</strong></a>
                                </a-tooltip>
                            </a-menu-item>

                            <a-menu-item :key="buttonKeyImprimirReporteTermico">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>Reporte Térmico {{ item.codigo }}</span>
                                    </template>
                                    <a href="#" @click.prevent="imprimirTermico(item.cotizacion_id)"><a-icon type="file-text" :style="{ fontSize: '18px', color: '#02c7c1' }"/> &nbsp;<strong>Comprobante Térmico</strong></a>
                                </a-tooltip>
                            </a-menu-item>

                            <a-menu-item :key="buttonKeyToVenta" v-if="$can('estimacion.convertir.cotizacion.en.venta')">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>Convertir en Venta {{ item.codigo }}</span>
                                    </template>
                                    <a href="#"><a-icon type="snippets" :style="{ fontSize: '18px', color: '#6f03f2' }"/> &nbsp;<strong>Convertir en Venta {{ item.codigo }}</strong></a>
                                </a-tooltip>
                            </a-menu-item>
                        </a-menu>
                        <a-button> Acciones <a-icon type="down" /> </a-button>
                    </a-dropdown>
                </template>
        </a-table>

        <a-modal
            v-model="modalDetalleSalida"
            title="Detalle Cotizacion"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="780"
            :zIndex="1050"
            >

            <div class="mb-12">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="invoice-title">
                            <h3>CODIGO DE COTIZACION</h3><h3 class="pull-right"><strong># {{ detalle.codigo }}</strong></h3>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-xs-6">
                                <address>
                                <strong>Datos Registrados: </strong><br>
                                    Sucursal : {{ detalle.almacen }}<br>
                                    Cliente : {{ detalle.cliente}}<br>
                                    NIT / CI : {{ detalle.nitcliente }}<br>
                                    Moneda : {{ detalle.tipocambio }} <br>
                                    <span v-if="detalle.estado == 'APROBADO'" style="color:green; font-weight:700;">Estado de Cotizacion : COTIZACION {{ detalle.estado }}</span>
                                    <span v-if="detalle.estado == 'ANULADO'" style="color:red; font-weight:700;">Estado de Cotizacion : COTIZACION {{ detalle.estado }}</span>
                                    Forma de Pago : {{ detalle.formapago }}
                                </address>
                            </div>
                            <div class="col-xs-6 text-right">
                                <address>
                                <strong>Datos Registrados:</strong><br>
                                    Usuario : {{ detalle.usuario }}<br>
                                    Fecha de Cotizacion: {{ detalle.fechaCotizacion }}<br>
                                    Hora de Cotizacion: {{ detalle.horaCotizacion }}<br>
									Validez Cotizacion : {{ detalle.validez }}<br>
									Tiempo de Entrega : {{ detalle.tiempoEntrega }}
                                </address>
                            </div>
                        </div>
                    </div>
                </div>    
                <div class="row">
                    <div class="col-md-12">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h3 class="panel-title"><strong>Detalle Registrado</strong></h3>
                            </div>
                            <div class="panel-body">
                                <div class="box-body table-responsive no-padding">
                                    <table class="table table-condensed">
                                        <thead>
                                            <tr>
                                                <th><strong>ITEM</strong></th>
                                                <th class="text-center"><strong>ARTICULO</strong></th>
                                                <th class="text-center"><strong>CANT</strong></th>
                                                <th class="text-center"><strong>P.VENTA</strong></th>
                                                <th class="text-center"><strong>DESC</strong></th>
                                                <th class="text-right"><strong>SUBTOTAL</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in detalle.listado" :key="index">
                                                <td>{{ index + 1}}</td>
                                                <td>{{ item.codigo }} {{ item.nombre }} {{ item.medida }}</td>
                                                <td class="text-center">{{ item.cantidad }}</td>
                                                <td class="text-center">{{ item.precioVenta }}</td>
                                                <td class="text-center">{{ item.descuento }}</td>
                                                <td class="text-right">{{ item.subtotal }}</td>
                                            </tr>
                                        </tbody> 
                                        <tfoot>       
                                            <tr>
                                                <td class="thick-line"></td>
                                                <td class="thick-line"></td>
                                                <td class="thick-line"></td>
                                                <td class="thick-line"></td>
                                                <td class="thick-line text-center"><strong>Subtotal</strong></td>
                                                <td class="thick-line text-right">{{ detalle.simbolo }} {{ detalle.subtotal }}</td>
                                            </tr>
                                            <tr>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line text-center"><strong>Descuento</strong></td>
                                                <td class="no-line text-right">{{ detalle.simbolo }} {{ detalle.descuento }}</td>
                                            </tr>
                                            <tr>
                                                <td class="no-line"><strong>SON:</strong></td>
                                                <td class="no-line">{{ detalle.montoLiteral }}</td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line text-center"><strong>Total</strong></td>
                                                <td class="no-line text-right" style="font-weight:700;">{{ detalle.simbolo }} {{ detalle.total }}</td>
                                            </tr>
                                            <tr>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line text-center"><strong>Cargos Extra</strong></td>
                                                <td bgcolor="#d0ff8e" class="no-line text-right">{{ detalle.simbolo }} {{ detalle.servicioExtra }}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalDetalleSalida = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>

        <!-- MODAL CONSULTAS OPCIONES -->
        <a-modal
            v-model="modalConsultaOpciones"
            title="Consultar Ventas"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="350"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <a-row type="flex">
                    <a-col flex="1 1 200px" :span="24" v-if="$can('estimacion.consultar.cotizacion.por.sucursal')">
                        <label for="almacen" class="control-label">Sucursal:</label>
                        <a-select size="large" label-in-value :value="{ key: consulta.almacen_id }" @change="onChageAlmacen"  style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24" v-if="$can('estimacion.consultar.cotizacion.por.usuario')">
                        <label for="proveedor" class="control-label">Usuarios :</label>
                        <a-select size="large" label-in-value :value="{ key: consulta.onlyusuario }" @change="onChageUsuario"  style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_usuarios" :key="index" :value="value.usuario"> {{ value.usuario }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24">
                        <label for="proveedor" class="control-label">Clientes :</label>
                        <a-select size="large" label-in-value :value="{ key: consulta.cliente_id }" @change="onChageCliente" show-search option-filter-prop="children" :filter-option="filterOptionCliente" style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_clientes" :key="index" :value="value.cliente_id"> {{ value.nombreCliente }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24">
                        <label for="nitProveedor" class="control-label">NIT / CI Clientes :</label>
                        <a-select size="large" label-in-value :value="{ key: consulta.cliente_id }" @change="onChageClienteNit" show-search option-filter-prop="children" :filter-option="filterOptionClienteNIT" style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_nitclientes" :key="index" :value="value.cliente_id"> {{ value.nitFactura }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24">
                        <label class="control-label">Accion:</label>
                        <a-button size="large" type="primary" block @click="obtenerListadoActual">
                            <a-icon type="reload" /> Reestablecer Opciones Busqueda
                        </a-button>
                    </a-col>
                </a-row>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalConsultaOpciones = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>

        <!-- GESTION EDITAR COTIZACION -->
        <a-modal
            v-model="modalEditarCotizacion"
            title="GESTION EDITAR COTIZACION"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="1300"
            :zIndex="1040"
            >

            <div style="margin-left: 30px;">
                <NuevaCotizacion
                    :nuevaventanacotizacionid="1"
                    :estadomoduloventa="`EDITAR`"
                    :updateventa="this.cotizacion"
                    @successEditarCotizacion="dataSuccessEditarCotizacion"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalEditarCotizacion = false">
                    Cerrar Modal
                </a-button>
            </template>
        </a-modal>

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 99999;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>

<script>
    const columns = [
        { 
            id: 'cotizacion_id', 
            title: 'Codigo', 
            dataIndex: 'codigo',
            key: 'codigo',
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            width: '15%'
        },
        { 
            id: 'cotizacion_id',
            title: 'Cliente', 
            dataIndex: '',
            key: 'cliente',
            scopedSlots: { customRender: 'cliente' },
            width: '30%',
            ellipsis: true
        },
        { 
            id: 'cotizacion_id',
            title: 'F.Registro', 
            dataIndex: '',
            key: 'fecha',
            scopedSlots: { customRender: 'fecha' },
            width: '18%'
        },
        { 
            id: 'cotizacion_id',
            title: 'Subtotal', 
            dataIndex: 'subtotal',
            width: '11%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'cotizacion_id',
            title: 'Desc', 
            dataIndex: 'descuento',
            width: '10%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'cotizacion_id',
            title: 'Total', 
            dataIndex: 'total',
            width: '10%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'cotizacion_id',
            title: 'Estado', 
            dataIndex: '',
            key: 'estado',
            scopedSlots: { customRender: 'estado' }, 
            width: '10%'
        },
        { 
            id: 'cotizacion_id',
            title: 'Acciones', 
            dataIndex: '',
            scopedSlots: { customRender: 'action' },
            width: '12%'
        }
    ]

	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
	import { EuropioCode } from '@/utils/europiocode'
	import axios from '@/config/axios/axios'
	import Swal from 'sweetalert2'
    import { mapGetters, mapActions } from 'vuex'
    import NuevaCotizacion from '../Modulos/NuevaCotizacion.vue'

    import { 
        URI_ALMACEN_GET_LISTAR,
        URI_USUARIO_LISTAR,
        URI_CLIENTE_LISTAR,
        URI_CLIENTE_LISTAR_NIT,
        URI_VENTA_SIN_FACTURA
	} from '@/utils/constants'

    export default {

        props: [
			'monedasimbolo', 
			'userid', 
			'almacenid'
		],

        components: {
            NuevaCotizacion
        },

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
				moment,
				locale,
                columns,
                utils,
                consulta: {
                    almacen_id: 0,
                    tiposalida_id: 0,
					plancuenta_id: 0,
					onlyusuario: 'Seleccionar',
                    cliente_id: 'Seleccionar',
                    nit_cliente: 'Seleccionar',
                    fecha_inicio: '',
                    fecha_final: '',
                    nombreAlmacen: '',
                    nombreTipoSalida: '',
                    nombrePlanCuenta: '',
                    nombreUsuario: '',
                    nombreCliente: '',
                    nombreClienteNIT: '',
                    keyword: ''
                },
                lista_almacenes: [],
				lista_clientes: [],
				lista_usuarios: [],
                lista_nitclientes: [],
                lista_productos: [],
                buttonKey: 1,
                showModal: false,
                detalle: {
                    codigo: '',
                    fechaCotizacion: '',
                    horaCotizacion: '',
                    subtotal: '',
                    descuento: '',
                    total: '',
                    usuario: '',
                    cliente: '',
                    nitcliente: '',
                    tipocambio: '',
                    tipocambioValor: '',
                    almacen: '',
                    simbolo: '',
                    montoLiteral: '',
                    nombreMoneda: '',
                    estado: '',
                    listado: [],
                    formapago: '',
                    validez: '',
                    tiempoEntrega: '',
                    servicioExtra: ''
                },
                list: [],
                url: '',
				search: { keyword: '' },
				spinnerloading: false,
                estadoTipoSalida: 0,
                listadosalida_ajuste: [],
                listadosalida: [],
				disabledButton: false,
				disabledButtonUsuario: false,
				lista_permisos: [],
                buttonKeyDescargarReporte: 10,
                buttonKeyVerReporte: 20,
                buttonKeyImprimirReporteTermico: 30,
                buttonKeyVerReporteTipoCambio: 40,
                buttonKeyAnularVenta: 50,
                buttonKeyImprimirReporte: 60,
                buttonKeyToVenta: 70,
                buttonKeyImprimirCuentaCobrar: 70,
                buttonKeyImprimirFacturarCuentaCobrar: 80,
                buttonKeyVisualizarComprobante: 90,
                buttonKeyEditarCotizacion: 100,
                showModalFacturarCredito: false,
                modalDetalleSalida: false,
                dataVenta: {
					cliente: '',
					codigoVenta: '',
					estado: '',
					estado_credito: '',
					fechaVenta: '',
					horaVenta: '',
					montoTotal: '',
					tipocambio: '',
					tiposalidaID: '',
					plancuentaID: '',
					totalVenta: '',
					usuario: ''
				},
                paginate: {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'cotizacion_id',
                    order: 'descend'
                },
                loading: false,
                modalConsultaOpciones: false,
                modalEditarCotizacion: false,
                cotizacion: {}
            }
        }, 
        
        mounted () {
			this.getAlmacenes()
			this.getTipoUsuarios()  
            this.getClientes()
            this.getClientesNit()
            this.paginarResultados()
        },

        computed: {
			...mapGetters('auth', ['user']),
            ...mapGetters('cotizacion', ['cotizaciones'])
		},

        methods: {
            ...mapActions('cotizacion', ['getPaginacion']),

            openMenuQueryCotizacion (name, query) {
				this.$router.push({
					name: name,
					params: { ventanacotizacionID: query }
				})
			},

            openModalConsultaOpciones () {
                this.modalConsultaOpciones = true
            },

            onChangeFechaInicio (date, dateString) {
				this.consulta.fecha_inicio = dateString
			},

            onChangeFechaFinal (date, dateString) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
				this.consulta.fecha_final = dateString
                this.paginarResultados()
			},

            filterOptionCliente (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            filterOptionClienteNIT (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            getAlmacenes () {
                axios.get(URI_ALMACEN_GET_LISTAR)
                .then(response => {
                    this.lista_almacenes = response.data
					this.consulta.almacen_id = Object.values(this.lista_almacenes).filter(x => typeof x !== 'undefined').shift().almacen_id
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },
			
			getTipoUsuarios () {
                axios.get(URI_USUARIO_LISTAR)
                .then(response => {
                    this.lista_usuarios = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },
            
            getClientes () {
                axios.get(URI_CLIENTE_LISTAR)
                .then(response => {
                    this.lista_clientes = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            getClientesNit () {
                axios.get(URI_CLIENTE_LISTAR_NIT)
                .then(response => {
                    this.lista_nitclientes = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            resetConsultaPaginacion () {
                this.paginate = {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'cotizacion_id',
                    order: 'descend'
                }

                this.consulta.tiposalida_id = 'Seleccionar'
				this.consulta.plancuenta_id = 'Seleccionar'
				this.consulta.onlyusuario = 'Seleccionar'
                this.consulta.cliente_id = 'Seleccionar'
                this.consulta.nit_cliente = 'Seleccionar'
                this.consulta.fecha_final = ''
                this.consulta.nombreTipoSalida = ''
                this.consulta.nombrePlanCuenta = ''
                this.consulta.nombreCliente = ''
                this.consulta.nombreClienteNIT = ''
                this.consulta.keyword = ''
            },

            paginarResultados () {
                this.consulta.almacen_id = (this.$can('estimacion.consultar.cotizacion.por.sucursal')) ? this.consulta.almacen_id : this.user.almacenID
                this.consulta.onlyusuario = (this.$can('estimacion.consultar.cotizacion.por.usuario')) ? this.consulta.onlyusuario : this.user.usuario

                this.getPaginacion({ ...this.paginate, ...this.consulta, ...this.user })
                .then(response => {
                    this.changePaginate(response.data.meta)
                    this.spinnerloading = false
                })
                .catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },

            handlePagination (paginate, filters, sorter) {
                this.paginate = {
                    pageSize: paginate.pageSize,
                    current: paginate.current,
                    total: paginate.total,
                    field: (sorter.field) ? sorter.field : 'cotizacion_id',
                    order: (sorter.order) ? sorter.order : 'descend'
                }
                this.paginarResultados()
            },

            changePaginate (data) {
                this.paginate = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'cotizacion_id',
                    order: 'descend'
                }
            },

            obtenerListadoActual (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.paginarResultados()
            },

            onChageAlmacen (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.almacen_id = value.key
                this.consulta.nombreAlmacen = value.label
                this.estadoTipoSalida = 0
                this.paginarResultados()
            },
			
            onChageUsuario (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.onlyusuario = value.key
                this.consulta.nombreUsuario = value.label
                this.estadoTipoSalida = 0
                this.paginarResultados()
            },

            onChageCliente (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.cliente_id = value.key
                this.consulta.nombreCliente = value.label
                this.estadoTipoSalida = 0
                this.paginarResultados()
            },

            onChageClienteNit (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.cliente_id = value.key
                this.consulta.nombreClienteNIT = value.label
                this.estadoTipoSalida = 0
                this.paginarResultados()
            },

            onSearchCotizacion (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.keyword = value
                this.paginarResultados()
            },

            abrirModalSalida (item) {
                this.detalle = []
                this.modalDetalleSalida = true
                
                this.detalle.codigo = item.codigo
                this.detalle.fechaCotizacion = item.fechaCotizacion
                this.detalle.horaCotizacion = item.horaCotizacion
                this.detalle.subtotal = item.subtotal
                this.detalle.descuento = item.descuento
                this.detalle.total = item.total
                this.detalle.usuario = item.usuario
                this.detalle.cliente = item.cliente.nombreFactura
                this.detalle.nitcliente = item.cliente.nitFactura
                this.detalle.tipocambio = item.tipocambio
                this.detalle.tipocambioValor = item.tipocambioValor
                this.detalle.almacen = item.almacen.nombre
                this.detalle.montoLiteral = item.montoLiteral
                this.detalle.simbolo = item.simbolo
                this.detalle.nombreMoneda = item.nombreMoneda
                this.detalle.estado = item.estado
                this.detalle.formapago = item.formapago
                this.detalle.validez = item.validez
                this.detalle.tiempoEntrega = item.tiempoEntrega
                this.detalle.servicioExtra = item.servicioExtra
                this.detalle.listado = item.cotizacionproductos
            },

            realizarPago (item) {
                Swal.fire({
                    title: 'Desea Agregar y Guardar?',
                    text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora (INTRO)',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder! (ESC)'
                }).then((result) => {
                    if (result.value) {
						this.spinnerloading = true
                        const productos = item.cotizacionproductos.map(function (value, index) {
							value.producto_id = value.productoID
                            value.descuentoVenta = value.descuento
                            value.importe_total_moneda = value.total
							return value
						})

                        const formData = new FormData()
						const ecode = new EuropioCode()
									
						formData.append('almacen_id', parseInt(item.almacenID))
						formData.append('tiposalida_id', parseInt(1))
						formData.append('plancuenta_id', parseInt(1))
						formData.append('clienteNIT', parseInt(item.cliente.nitFactura))
						formData.append('tipocambio', parseInt(0))
						formData.append('clienteNombre', ecode.encode(item.cliente.nombreFactura))
						formData.append('responsable', 'SIN NOMBRE')
						formData.append('solicitantenombre', 'SIN NOMBRE')
						formData.append('observaciones', ecode.encode(item.observacion))
						formData.append('fechaCredito', '')
						formData.append('fechaVentaElegido', ecode.encode(item.fechaCotizacion))
						formData.append('codigoSolicitante', '')
					    formData.append('montoTotal', ecode.encode(item.subtotal.toString()))
						formData.append('descuento', ecode.encode(item.descuento.toString()))
						formData.append('descuentoPorcentajeTotal', 0)
						formData.append('totalVenta', ecode.encode(item.total.toString()))
						formData.append('efectivo', 0)
						formData.append('cambio', 0)
						formData.append('totalBaseVentaMoneda', '')
						formData.append('tipocambioValor', '')
						formData.append('totalVentaCambioMoneda', '')
						formData.append('productos', ecode.encode(JSON.stringify(productos)))

                        axios.post(URI_VENTA_SIN_FACTURA, formData)
						.then(response => {
							if (response.status === 200) {
                                utils.openReciboMobile(response.data.urlprint)
								this.spinnerloading = false
                            }
                        })
						.catch(error => {
							utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
						})
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
				this.buttonKey++
            },

            /** EDITAR COTIZACION */
            openModalEditarCotizacion (item) {
                setTimeout(function () { 
                    this.cotizacion = item
                    this.modalEditarCotizacion = true
                }.bind(this), 500)
            },

            dataSuccessEditarCotizacion (newValue) {
                setTimeout(function () {
                    this.paginarResultados()
                    this.modalEditarCotizacion = false
                    utils.openNotificationWithIcon('success', 'Mensaje : ', utils.showErrors(newValue), 'topRight')
                }.bind(this), 300)
            },

            imprimirTermico (cotizacionID) {
                utils.openReciboMobile(`/reporte/cotizacion/termica/${cotizacionID}`)
            },

            reporteCarta (cotizacionID) {
                utils.openReciboMobile(`/reporte/cotizacion/reporte/carta/${cotizacionID}`)
            }
        }
    }
</script>